<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-briefcase" style="font-size: 1.6rem"></i> Cadastro de Imobiliária</h3>
      </div>

      <div class="p-col-12 p-md-6">
        <Button
          id="buttonGravar"
          label="Gravar"
          icon="pi pi-check"
          style="float: right"
          class="p-mr-2 p-mb-3 p-button-lg p-button-success"
          @click="criar_alterar"
          v-if="$auth.userCan('IMOBILIARIAS_CADASTRAR')"
        ></Button>
      </div>
    </div>

    <TabView>
      <TabPanel header="1: Informações Gerais">
        <div class="p-grid p-pt-3">
          <div class="p-col-12 p-md-6">
            <!-- Informações Básicas -->
            <h5><i class="pi pi-id-card"></i> Identificação</h5>
            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-3">
                  <label for="cpfcnpj">CPF/CNPJ</label>
                  <InputText
                    id="cpfcnpj"
                    type="text"
                    v-model="cpfcnpj"
                    @blur="validaCpfCnpj"
                    @focus="focoCpfCnpj"
                    :class="(fieldsError.some(v => v === 'cpfcnpj'))?'p-invalid':''"
                  />
                  <InlineMessage v-if="cpfcnpjInvalido">CPF/CNPJ Inválido</InlineMessage>
                </div>
                <div class="p-field p-md-9">
                  <label for="nome">Nome Completo / Razão Social</label>
                  <InputText
                    id="nome"
                    type="text"
                    v-model="nome"
                    maxlength="200"
                    :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
                  />
                </div>

                <div class="p-field p-col-12">
                  <label for="email">E-Mail</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model="email"
                    :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
                    maxlength="200"
                  />
                </div>
              </div>
            </div>

            <!-- Telefones -->
            <h5><i class="pi pi-phone"></i> Telefones</h5>
            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-6">
                  <label for="telefone_nome">Nome da Pessoa ou Departamento</label>
                  <InputText
                    id="telefone_nome"
                    type="text"
                    v-model="telefone_nome"
                    :class="(fieldsError.some(v => v === 'telefone_nome'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-6">
                  <label for="telefone">Número de Telefone</label>
                  <InputMask
                    id="telefone"
                    type="text"
                    v-model="telefone"
                    :class="(fieldsError.some(v => v === 'telefone'))?'p-invalid':''"
                    mask="(99) 9999-9999?9"
                    @focus="telefoneInvalido = false"
                  />
                  <InlineMessage v-if="telefoneInvalido">
                    O Telefone é inválido
                  </InlineMessage>
                </div>

                <div class="p-field p-md-6">
                  <div class="p-field-checkbox">
                    <Checkbox
                      id="e_whatsapp"
                      v-model="telefone_e_whatsapp"
                      :binary="true"
                    />
                    <label for="e_whatsapp">O telefone é WhatsApp.</label>
                  </div>
                </div>

                <div class="p-field p-md-6">
                  <Button
                    label="Adicionar Telefone"
                    @click="adicionarTelefone"
                  ></Button>
                </div>

                <div class="p-field" v-if="telefones.length">
                  <DataTable sortMode="single" :value="telefones">
                    <Column field="nome" headerClass="colNome" header="Pessoa ou Departamento">
                      <template #body="slotProps">
                        {{
                          slotProps.data.nome
                            ? slotProps.data.nome
                            : "Número Comercial"
                        }}
                      </template>
                    </Column>
                    <Column
                      field="telefone"
                      headerClass="colTelefone"
                      headerStyle="width:145px"
                      header="Telefone"
                    >
                      <template #body="slotProps">
                        {{ $utils.formatPhone(slotProps.data.telefone) }}
                      </template>
                    </Column>
                    <Column
                      field="tipo"
                      headerClass="colTipo"
                      headerStyle="width:67px"
                      header="Tipo"
                    ></Column>
                    <Column
                      field="e_whatsapp"
                      headerClass="colWhatsapp"
                      headerStyle="width:65px"
                      header="Whatsapp?"
                    >
                      <template #body="slotProps">
                        {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                      </template>
                    </Column>
                    <Column headerStyle="width:52px">
                      <template #body="slotProps">
                        <Button
                          icon="pi pi-trash"
                          class="p-mr-2 p-mb-2 p-button-danger"
                          @click="removerTelefone($event, slotProps.index)"
                        ></Button>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <!-- Endereço -->
            <h5><i class="pi pi-map-marker"></i> Endereço Comercial</h5>
            <div class="card p-fluid">
              <div class="p-grid">
                <div class="p-field p-md-3">
                  <label for="comercial_cep">CEP</label>
                  <InputMask
                    id="comercial_cep"
                    type="text"
                    v-model="comercial_cep"
                    :class="(fieldsError.some(v => v === 'comercial_cep'))?'p-invalid':''"
                    mask="99.999-999"
                    :unmask="true"
                    @change="validaCEP"
                    @focus="cepInvalido = false"
                  />
                  <InlineMessage v-if="cepInvalido">CEP Inválido</InlineMessage>
                </div>

                <div class="p-field p-md-9">
                  <label for="comercial_logradouro">Endereço</label>
                  <InputText
                    id="comercial_logradouro"
                    type="text"
                    v-model="comercial_logradouro"
                    :class="(fieldsError.some(v => v === 'comercial_logradouro'))?'p-invalid':''"
                    maxlength="300"
                  />
                </div>

                <div class="p-field p-md-2">
                  <label for="comercial_numero">Número</label>
                  <InputText
                    id="comercial_numero"
                    type="text"
                    v-model="comercial_numero" :class="(fieldsError.some(v => v === 'comercial_numero'))?'p-invalid':''"
                    maxlength="10"
                  />
                </div>

                <div class="p-field p-md-10">
                  <label for="comercial_complemento">Complemento</label>
                  <InputText
                    id="comercial_complemento"
                    type="text"
                    v-model="comercial_complemento" :class="(fieldsError.some(v => v === 'comercial_complemento'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="comercial_bairro">Bairro</label>
                  <InputText
                    id="comercial_bairro"
                    type="text"
                    v-model="comercial_bairro" :class="(fieldsError.some(v => v === 'comercial_bairro'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-5">
                  <label for="comercial_cidade">Cidade</label>
                  <InputText
                    id="comercial_cidade"
                    type="text"
                    v-model="comercial_cidade" :class="(fieldsError.some(v => v === 'comercial_cidade'))?'p-invalid':''"
                    maxlength="100"
                  />
                </div>

                <div class="p-field p-md-2">
                  <label for="comercial_uf">UF</label>
                  <InputText
                    id="comercial_uf"
                    type="text"
                    v-model="comercial_uf" :class="(fieldsError.some(v => v === 'comercial_uf'))?'p-invalid':''"
                    maxlength="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="2: Contatos">
        <div class="p-grid p-pt-3">
          <div class="p-col-12">

            <!-- Contatos -->
            <h5><i class="pi pi-users"></i> Contatos</h5>
            <div class="card p-fluid">
              <div class="p-grid">

                <div class="p-field p-md-4">
                  <label for="contato_nome">Nome</label>
                  <InputText
                    id="contato_nome"
                    type="text"
                    v-model="contato_nome"
                    :class="(fieldsError.some(v => v === 'contato_nome'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-4">
                  <label for="contato_cargo">Cargo</label>
                  <InputText
                    id="contato_cargo"
                    type="text"
                    v-model="contato_cargo"
                    :class="(fieldsError.some(v => v === 'contato_cargo'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-4">
                  <label for="contato_email">E-mail</label>
                  <InputText
                    id="contato_email"
                    type="text"
                    v-model="contato_email"
                    :class="(fieldsError.some(v => v === 'contato_email'))?'p-invalid':''"
                    maxlength="150"
                  />
                </div>

                <div class="p-field p-md-3">
                  <label for="contato_nascimento">Nascimento</label>
                  <InputMask
                    v-model="contato_nascimento" 
                    :class="(fieldsError.some(v => v === 'contato_nascimento'))?'p-invalid':''"
                    mask="99/99/9999"
                    slotChar="dd/mm/aaaa"
                  />
                </div>

                <div class="p-field p-md-3">
                  <label for="contato_telefone">Número de Telefone</label>
                  <InputMask
                    id="contato_telefone"
                    type="text"
                    v-model="contato_telefone"
                    :class="(fieldsError.some(v => v === 'contato_telefone'))?'p-invalid':''"
                    mask="(99) 9999-9999?9"
                    @focus="contatoTelefoneInvalido = false"
                  />
                  <InlineMessage v-if="contatoTelefoneInvalido">
                    O Telefone é inválido
                  </InlineMessage>
                </div>

                <div class="p-field p-md-3">
                  <label>&nbsp;</label>
                  <div class="p-field-checkbox p-mt-2">
                    <Checkbox
                      id="contato_e_whatsapp"
                      v-model="contato_telefone_e_whatsapp"
                      :binary="true"
                    />
                    <label for="contato_e_whatsapp">É WhatsApp.</label>
                  </div>
                </div>

                <div class="p-field p-md-3">
                  <label>&nbsp;</label>
                  <Button
                    label="Adicionar Contato"
                    @click="adicionarContato"
                  ></Button>
                </div>

                <div class="p-field p-col-12" v-if="contatos.length">
                  <DataTable sortMode="single" :value="contatos">
                    <Column 
                      field="nome" 
                      header="Nome"
                    ></Column>
                    <Column 
                      field="cargo" 
                      header="Cargo"
                    ></Column>
                    <Column
                      field="telefone"
                      headerClass="colTelefone"
                      headerStyle="width:145px"
                      header="Telefone"
                    >
                      <template #body="slotProps">
                        {{ $utils.formatPhone(slotProps.data.telefone) }}
                      </template>
                    </Column>
                    <Column
                      field="e_whatsapp"
                      headerClass="colWhatsapp"
                      headerStyle="width:65px"
                      header="Whatsapp?"
                    >
                      <template #body="slotProps">
                        {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                      </template>
                    </Column>
                    <Column
                      field="nascimento"
                      headerClass="colNascimento"
                      headerStyle="width:145px"
                      header="Data Nascimento"
                    ></Column>
                    <Column
                      field="email"
                      headerClass="colEmail" 
                      header="E-Mail"
                    ></Column>
                    <Column headerStyle="width:52px">
                      <template #body="slotProps">
                        <Button
                          icon="pi pi-trash"
                          class="p-mr-2 p-mb-2 p-button-danger"
                          @click="removerContato($event, slotProps.index)"
                        ></Button>
                      </template>
                    </Column>
                  </DataTable>
                </div>

              </div>
            </div>

          </div>
        </div>
      </TabPanel>
      <TabPanel header="3: Configurações de Seguradoras">
        <!-- Seguradoras -->

        <div class="p-grid">

          <div class="p-md-6 p-fluid">
            <h5 class="p-mt-2 p-mb-5"><i class="pi pi-percentage"></i> Taxas</h5>

            <div class="p-grid">

              <div class="p-field p-md-3">
                <label for="seguradora">Seguradora</label>
                <Dropdown
                  id="seguradora"
                  v-model="seguradora"
                  :options="seguradoras"
                  optionLabel="nome"
                  optionValue="id"
                  placeholder="Selecione"
                />
              </div>

              <div class="p-field p-md-3">
                <label for="plano">Plano</label>
                <Dropdown
                  id="plano"
                  v-model="plano"
                  :options="planos"
                  optionLabel="nome"
                  optionValue="id"
                  placeholder="Selecione"
                />
              </div>

              <div class="p-field p-md-3">
                <label for="taxa">Taxa</label>
                <InputNumber
                  id="taxa"
                  v-model="taxa"
                  mode="decimal"
                  locale="pt-BR"
                  :minFractionDigits="2" 
                  :maxFractionDigits="2"
                  suffix=" %"
                  :min="0.00" :max="100.00"
                  :class="
                    fieldsError.some((v) => v === 'taxa')
                      ? 'p-invalid'
                      : ''
                  "
                  @focus="$utils.inputNumberFocus"
                />
              </div>

              <div class="p-field p-md-3">
                <Button
                  label="Adicionar"
                  class="p-mt-4"
                  @click="adicionarConfiguracaoSeguradora"
                ></Button>
              </div>
            
            </div>

            <div class="p-field" v-if="configuracoes_seguradoras.length">
              <DataTable sortMode="single" :value="configuracoes_seguradoras">
                <Column field="seguradora_nome" headerClass="colSeguradora" header="Seguradora"></Column>
                <Column field="plano_nome" headerClass="colPlano" header="Plano">
                  <template #body="slotProps">
                    {{ (slotProps.data.plano)?slotProps.data.plano_nome:"Todos" }}
                  </template>
                </Column>
                <Column
                  field="taxa"
                  headerClass="colTaxa"
                  header="Taxa"
                >
                  <template #body="slotProps">
                    {{ new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(slotProps.data.taxa) }} %
                  </template>
                </Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerConfiguracaoSeguradora($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>

          </div>
          <div class="p-md-1"></div>
          <div class="p-md-5 p-fluid">
            <h5 class="p-mt-2 p-mb-5"><i class="pi pi-globe"></i> Identificação junto às Seguradoras</h5>

            <div class="p-grid">

              <div class="p-field p-md-4">
                <label for="identificacao_seguradora">Seguradora</label>
                <Dropdown
                  id="identificacao_seguradora"
                  v-model="identificacao_seguradora"
                  :options="seguradoras"
                  optionLabel="nome"
                  optionValue="id"
                  placeholder="Selecione"
                />
              </div>

              <div class="p-field p-md-4">
                <label for="identificacao_codigo">Código</label>
                <InputText
                  id="identificacao_codigo"
                  type="text"
                  v-model="identificacao_codigo"
                  :class="
                    fieldsError.some((v) => v === 'identificacao_codigo')
                      ? 'p-invalid'
                      : ''
                  "
                />
              </div>

              <div class="p-field p-md-4">
                <Button
                  label="Adicionar"
                  class="p-mt-4"
                  @click="adicionarIdentificacaoSeguradora"
                ></Button>
              </div>
            
            </div>

            <div class="p-field" v-if="identificacoes_seguradoras.length">
              <DataTable sortMode="single" :value="identificacoes_seguradoras">
                <Column field="seguradora_nome" headerClass="colSeguradora" header="Seguradora"></Column>
                <Column field="codigo" headerClass="colCodigo" header="Código"></Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerIdentificacaoSeguradora($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>

          </div>
          <div class="p-md-5 p-fluid">
            <h5 class="p-mt-2 p-mb-5"><i class="pi pi-lock"></i> Bloqueio de Seguradoras</h5>

            <div class="p-grid">

              <div class="p-field p-md-7">
                <label for="bloqueio_seguradora">Seguradora</label>
                <Dropdown
                  id="bloqueio_seguradora"
                  v-model="bloqueio_seguradora"
                  :options="seguradoras"
                  optionLabel="nome"
                  optionValue="id"
                  placeholder="Selecione"
                />
              </div>

              <div class="p-field p-md-4">
                <Button
                  label="Adicionar"
                  class="p-mt-4"
                  @click="adicionarBloqueioSeguradora"
                ></Button>
              </div>
            
            </div>

            <div class="p-field" v-if="bloqueios_seguradoras.length">
              <DataTable sortMode="single" :value="bloqueios_seguradoras">
                <Column field="seguradora_nome" headerClass="colSeguradora" header="Seguradora"></Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerBloqueioSeguradora($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>

          </div>

        </div>

      </TabPanel>
    </TabView>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 1. Controle de apresentação
      loading: 2,

      // 1.1. Validadores
      telefoneInvalido: false,
      contatoTelefoneInvalido: false,
      cepInvalido: false,
      cpfcnpjInvalido: false,
      fieldsError: [],

      // 2. Chaves Estrangeiras
      sim_nao: [
        { nome: "Sim", id: 1 },
        { nome: "Não", id: 0 },
      ],
      seguradoras: [],
      planos: [],

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      cpfcnpj: "",
      nome: "",
      email: "",

      telefone_nome: "",
      telefone_e_whatsapp: false,
      telefone: "",

      telefones: [],

      comercial_cep: "",
      comercial_logradouro: "",
      comercial_numero: "",
      comercial_complemento: "",
      comercial_bairro: "",
      comercial_cidade: "",
      comercial_uf: "",

      contato_nome: "",
      contato_cargo: "",
      contato_email: "",
      contato_nascimento: "",
      contato_telefone: "",
      contato_telefone_e_whatsapp: false,

      contatos: [],

      configuracoes_seguradoras: [],

      seguradora: "",
      plano: "",
      taxa: 0,

      identificacoes_seguradoras: [],

      identificacao_seguradora: "",
      identificacao_codigo: "",

      bloqueios_seguradoras: [],

      bloqueio_seguradora: "",

      // 4. Dado selecionado para edição

      imobiliaria: null,
      
    };
  },

  mounted() {

    const self = this;

    //TODO VERIFICAR PORQUE NÃO TRÁS APENAS OS CAMPOS SELECIONADOS DO RELACIONAMENTO
    this.$api.get("/seguradoras?fields=nome&relationships=planos&filters={%22status%22:[%22ATIVO%22]}").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.seguradoras = dados.data;
      }

      self.loading--;

    });

    this.carregarObjetoRota();
  },

  watch: {
    
    $route() {
      if(this.$route.name == "imobiliaria")
        this.carregarObjetoRota();
    },

    imobiliaria: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        // 3. Dados de formulários

        // 3.1. Informações Gerais

        this.cpfcnpj = val.cpfcnpj;
        this.nome = val.nome;
        this.email = val.email;

        if(Array.isArray(val.contatos)) {
          for(const c of val.contatos) {
            this.telefones.push({
              nome: c.nome,
              telefone: c.telefone,
              tipo: c.tipo,
              e_whatsapp: c.e_whatsapp,
            });
          }
        }

        if(Array.isArray(val.contatos_imobiliaria)) {
          for(const c of val.contatos_imobiliaria) {
            this.contatos.push({
              nome: c.nome,
              email: c.email,
              cargo: c.cargo,
              nascimento: this.$utils.formatDateToBr(c.nascimento),
              telefone: c.telefone,
              e_whatsapp: c.e_whatsapp,
            });
          }
        }

        this.comercial_cep = val.cep;
        this.comercial_logradouro = val.logradouro;
        this.comercial_numero = val.numero;
        this.comercial_complemento = val.complemento;
        this.comercial_bairro = val.bairro;
        this.comercial_cidade = val.cidade;
        this.comercial_uf = val.uf;

        this.configuracoes_seguradoras = val.seguradoras;
        this.identificacoes_seguradoras = val.identificacoes;
        this.bloqueios_seguradoras = val.bloqueios;

      }
    },

    seguradora: function(val) {

      if(val) {

        const seguradora = this.seguradoras.filter(function(el) {
          return el.id == val;
        });

        this.planos = seguradora[0].planos;
      } else {
        this.planos = [];
      }

      this.plano = "";

    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {
      const self = this;

      if (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      ) {
        self.loading--;
        return;
      }

      let bloqueio = setInterval(() => {

        if(self.loading == 1) {

          self.$api
            .get("/imobiliarias/" + self.$route.params.id)
            .then(function (response) {
              const dados = response.data;
              if (dados.success) { 
                self.imobiliaria = dados.data;
                self.loading = false;
              } else {
                alert(dados.message);
                self.$router.push({ path: `/imobiliarias` });
              }

              self.loading--;

              clearInterval(bloqueio);

            });

        }

      },500);

    },

    limparFormulario() {
      //Limpa as variáveis

      // 1.1. Validadores
      this.contatoTelefoneInvalido = false;
      this.telefoneInvalido = false;
      this.cepInvalido = false;
      this.cpfcnpjInvalido = false;
      this.fieldsError = [];

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      this.cpfcnpj = "";
      this.nome = "";
      this.email = "";

      this.telefone_nome = "";
      this.telefone_e_whatsapp = false;
      this.telefone = "";

      this.telefones = [];

      this.comercial_cep = "";
      this.comercial_logradouro = "";
      this.comercial_numero = "";
      this.comercial_complemento = "";
      this.comercial_bairro = "";
      this.comercial_cidade = "";
      this.comercial_uf = "";

      this.contato_nome = "";
      this.contato_cargo = "";
      this.contato_email = "";
      this.contato_nascimento = "";
      this.contato_telefone = "";
      this.contato_telefone_e_whatsapp = false;

      this.contatos = [];
      
      this.configuracoes_seguradoras = [];
      this.identificacoes_seguradoras = [];
      this.bloqueios_seguradoras = [];

      this.seguradora = "";
      this.taxa = 0;

    },

    // // // AÇÕES DO USUÁRIO // // //

    validaCEP() {

      const self = this;

      axios
        .get("https://brasilapi.com.br/api/cep/v1/" + this.comercial_cep)
        .then(function (response) {
          //Sucesso
          if (!self.comercial_uf) self.comercial_uf = response.data.state;
          if (!self.comercial_cidade) self.comercial_cidade = response.data.city;
          if (!self.comercial_bairro) self.comercial_bairro = response.data.neighborhood;
          if (!self.comercial_logradouro) self.comercial_logradouro = response.data.street;
        })
        .catch(function (error) {
          //Erro
          if (error.response.data.name == "CepPromiseError")
            self.cepInvalido = true;
        });
    },

    focoCpfCnpj() {
      this.cpfcnpjInvalido = false;
      this.cpfcnpj = this.cpfcnpj.replace(/[^\d]+/g, "");
    },

    validaCpfCnpj() {

      this.cpfcnpjInvalido = false;

      this.cpfcnpj = this.cpfcnpj.replace(/[^\d]+/g, "");

      if(this.cpfcnpj.length == 11) {
        this.cpfcnpjInvalido = !this.$utils.isCPF(this.cpfcnpj);
      } else if(this.cpfcnpj.length == 14) {
        this.cpfcnpjInvalido = !this.$utils.isCNPJ(this.cpfcnpj);
      } else {
        this.cpfcnpjInvalido = true;
      }

      if(this.cpfcnpjInvalido == false)
        this.cpfcnpj = this.$utils.formatCpfCnpj(this.cpfcnpj);

    },

    adicionarTelefone() {
      if (this.telefone.length == 15 || this.telefone.length == 14) {
        this.telefoneInvalido = false;

        this.telefones.push({
          nome: this.telefone_nome,
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          tipo: this.telefone.substr(5, 1) == "9" ? "Móvel" : "Fixo",
          e_whatsapp: (this.telefone_e_whatsapp)?1:0,
        });
      } else {
        this.telefoneInvalido = true;
      }
    },

    adicionarContato() {

      this.fieldsError = [];
      this.contatoTelefoneInvalido = false;

      if (this.contato_telefone.length != 15 && this.contato_telefone.length != 14) {
        this.contatoTelefoneInvalido = true;
        this.fieldsError.push("contato_telefone");
      }      

      for(const f of ["contato_nome", "contato_cargo", "contato_email", "contato_nascimento"]) {
        if(this[f] == "") {
          this.fieldsError.push(f);
        }
      }

      if(this.fieldsError.length) {

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha corretamente as informações obrigatórias!",
          life: 5000,
        });
        return;

      }

      this.contatos.push({
        nome: this.contato_nome,
        email: this.contato_email,
        cargo: this.contato_cargo,
        nascimento: this.contato_nascimento,
        telefone: this.contato_telefone.replace(/[^0-9]/g, ""),
        e_whatsapp: (this.contato_telefone_e_whatsapp)?1:0,
      });

      this.contato_nome = "";
      this.contato_cargo = "";
      this.contato_email = "";
      this.contato_nascimento = "";
      this.contato_telefone = "";
      this.contato_telefone_e_whatsapp = false;

    },

    removerContato(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.contatos.splice(index, 1);
        },
      });
    },

    adicionarConfiguracaoSeguradora() {
      if (this.seguradora != "") {

        const self = this;
        
        let result = this.seguradoras.filter(function (el) {
          return self.seguradora == el.id;
        });

        const seguradora_nome = result[0].nome;

        result = result[0].planos.filter(function (el) {
          return self.plano == el.id;
        });

        const plano_nome = (result.length)?result[0].nome:"";

        this.configuracoes_seguradoras.push({
          seguradora: this.seguradora,
          seguradora_nome: seguradora_nome,
          plano: this.plano,
          plano_nome: plano_nome,
          taxa: this.taxa,
        });


      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Seguradora não selecionada!",
          life: 5000,
        });

      }
    },

    adicionarIdentificacaoSeguradora() {
      if (this.identificacao_seguradora != "" && this.identificacao_codigo != "") {

        const self = this;
        
        let result = this.seguradoras.filter(function (el) {
          return self.identificacao_seguradora == el.id;
        });

        const seguradora_nome = result[0].nome;

        this.identificacoes_seguradoras.push({
          seguradora: this.identificacao_seguradora,
          seguradora_nome: seguradora_nome,
          codigo: this.identificacao_codigo,
        });

      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Seguradora e/ou código não informado(s)!",
          life: 5000,
        });

      }
    },

    adicionarBloqueioSeguradora() {
      if (this.bloqueio_seguradora != "") {

        const self = this;
        
        let result = this.seguradoras.filter(function (el) {
          return self.bloqueio_seguradora == el.id;
        });

        const seguradora_nome = result[0].nome;

        this.bloqueios_seguradoras.push({
          seguradora: this.bloqueio_seguradora,
          seguradora_nome: seguradora_nome,
        });

      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Seguradora não informada!",
          life: 5000,
        });

      }
    },

    removerTelefone(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.telefones.splice(index, 1);
        },
      });
    },

    removerConfiguracaoSeguradora(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.configuracoes_seguradoras.splice(index, 1);
        },
      });
    },

    removerIdentificacaoSeguradora(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.identificacoes_seguradoras.splice(index, 1);
        },
      });
    },

    removerBloqueioSeguradora(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.bloqueios_seguradoras.splice(index, 1);
        },
      });
    },

    criar_alterar() {
      const self = this;
      const criar = (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      );

      this.fieldsError = [];

      let contatos_imobiliaria = this.$utils.getStdObject(this.contatos);
      for(const c of contatos_imobiliaria) {
        c.nascimento = this.$utils.formatDateToMysql(c.nascimento);
      }

      let dados = {
        "tipo": 'IMOBILIARIA',
        "nome": this.nome,
        "cpfcnpj": this.cpfcnpj.replace(/[^0-9]/g, ""),
        "logradouro": this.comercial_logradouro,
        "numero": this.comercial_numero,
        "complemento": this.comercial_complemento,
        "bairro": this.comercial_bairro,
        "cidade": this.comercial_cidade,
        "uf": this.comercial_uf,
        "cep": this.comercial_cep.replace(/[^0-9]/g, ""),
        "email": this.email,

        // "cnae": null,
        "contatos": this.telefones,
        "contatos_imobiliaria": contatos_imobiliaria,
        "seguradoras": this.configuracoes_seguradoras,
        "identificacoes": this.identificacoes_seguradoras,
        "bloqueios": this.bloqueios_seguradoras
      };

      if (!criar) {
        dados["_method"] = "PUT";
      }

      this.$api
        .post(
          "/imobiliarias" + (!criar ? "/" + this.$route.params.id : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {
                "logradouro": "comercial_logradouro",
                "numero": "comercial_numero",
                "complemento": "comercial_complemento",
                "bairro": "comercial_bairro",
                "cidade": "comercial_cidade",
                "uf": "comercial_uf",
                "cep": "comercial_cep",                
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    }

  },
};
</script>

<style scoped>
</style>